/* inter-100normal - latin */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-display: swap;
  font-weight: 100;
  src:
    local('Inter Thin '),
    local('Inter-Thin'),
    url('./files/inter-latin-100.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/inter-latin-100.woff') format('woff'); /* Modern Browsers */
}

/* inter-200normal - latin */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-display: swap;
  font-weight: 200;
  src:
    local('Inter Extra Light '),
    local('Inter-Extra Light'),
    url('./files/inter-latin-200.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/inter-latin-200.woff') format('woff'); /* Modern Browsers */
}

/* inter-300normal - latin */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src:
    local('Inter Light '),
    local('Inter-Light'),
    url('./files/inter-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/inter-latin-300.woff') format('woff'); /* Modern Browsers */
}

/* inter-400normal - latin */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src:
    local('Inter Regular '),
    local('Inter-Regular'),
    url('./files/inter-latin-400.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/inter-latin-400.woff') format('woff'); /* Modern Browsers */
}

/* inter-500normal - latin */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src:
    local('Inter Medium '),
    local('Inter-Medium'),
    url('./files/inter-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/inter-latin-500.woff') format('woff'); /* Modern Browsers */
}

/* inter-600normal - latin */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-display: swap;
  font-weight: 600;
  src:
    local('Inter SemiBold '),
    local('Inter-SemiBold'),
    url('./files/inter-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/inter-latin-600.woff') format('woff'); /* Modern Browsers */
}

/* inter-700normal - latin */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src:
    local('Inter Bold '),
    local('Inter-Bold'),
    url('./files/inter-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/inter-latin-700.woff') format('woff'); /* Modern Browsers */
}

/* inter-800normal - latin */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-display: swap;
  font-weight: 800;
  src:
    local('Inter ExtraBold '),
    local('Inter-ExtraBold'),
    url('./files/inter-latin-800.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/inter-latin-800.woff') format('woff'); /* Modern Browsers */
}

/* inter-900normal - latin */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-display: swap;
  font-weight: 900;
  src:
    local('Inter Black '),
    local('Inter-Black'),
    url('./files/inter-latin-900.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/inter-latin-900.woff') format('woff'); /* Modern Browsers */
}

